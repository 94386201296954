<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { useInfiniteScroll } from '@vueuse/core'
import { v4 as uuid } from 'uuid'
import { api } from '@/api'
import { useHistoryStore, useQueryStore, useToastStore, useTrackValueStore } from '@/stores'
import { useGotoBack } from '@/composables'
import { TRACK_CHANNEL_NAME_VALUE } from '@/constants'

const { $toast } = useToastStore()
const gotoBack = useGotoBack()

const inputRef = ref<HTMLInputElement>()
const input = ref('')
function onClear() {
  input.value = ''
}

const route = useRoute()
const router = useRouter()
onMounted(() => {
  if (route.query.kw) {
    input.value = route.query.kw as string
    onSearch()
  }
})

const skeleton = ref(false)
const fetching = ref(false)
const ending = ref(false)
const error = ref<any>()
const page = ref(1)
const pagination = computed(() => ({
  pageIndex: page.value,
  pageSize: 20,
}))

const passcodePID = ref('')
const passcode = ref<any>()
const result = ref<any[]>([])

async function onSearch() {
  skeleton.value = true
  ending.value = false
  error.value = null
  page.value = 1
  result.value = []
  fetchResult()
}

async function fetchResult() {
  fetching.value = true
  try {
    const { data } = await api.get<any, GetContentGetcontentbypassornameResponse>('/content/getContentByPassOrName', { params: { passOrName: input.value, ...pagination.value } })
    passcode.value = data.codeSearchResult
    passcodePID.value = data.promotionId ?? ''

    if (!data.nameSearchResult) {
      ending.value = true
      return
    }

    result.value = [...result.value, ...(data.nameSearchResult.records ?? [])]
    if (page.value >= data.nameSearchResult.pages)
      ending.value = true
    else
      page.value += 1
  }
  catch (_error: any) {
    console.error(_error)
    error.value = _error
    $toast(_error.msg || '服务器错误')
  }
  finally {
    fetching.value = false
    skeleton.value = false
  }
}

const infiniteRef = ref<HTMLElement>()
useInfiniteScroll(infiniteRef,
  async () => {
    if (fetching.value || ending.value || error.value)
      return
    await fetchResult()
  },
  { distance: 50 },
)

const queryStore = useQueryStore()
const historyStore = useHistoryStore()
async function gotoPasscodeBook(passcode: any) {
  if (passcodePID.value) // Note: 如果存在 promotionId, 需要更新全局 `max_pid`
    queryStore.$state.max_pid = passcodePID.value

  const trackValueStore = useTrackValueStore()
  trackValueStore.set({ bookId: passcode.id, firstOrigin: 3, originName: 3, chlName: TRACK_CHANNEL_NAME_VALUE.SEARCH_LIST })
  // Note: clear custom history stack, avoid back to old `max_pid`
  historyStore.$clear()
  router.replace({ name: 'book', params: { contentId: passcode.id } })
}

function onTrackGotoBook(bookId: string) {
  const trackValueStore = useTrackValueStore()
  trackValueStore.set({ bookId, firstOrigin: 3, originName: 3, chlName: TRACK_CHANNEL_NAME_VALUE.SEARCH_LIST })
  return true
}
</script>

<template>
  <div ref="infiniteRef" class="px-15px py-10px h-screen bg-white overflow-scroll">
    <div class="w-full flex items-center space-x-5 relative">
      <i-assets-icon-search class="w-4 h-4 absolute left-3 top-2.5 text-#86909C" />
      <input
        ref="inputRef"
        v-model="input" enterkeyhint="search"
        class="ml0! flex-1 h-9 pl-9 pr-4 rounded-full border-0 !outline-0 truncate caret-primary !bg-#000 !bg-opacity-4 placeholder:text-14px"
        placeholder="Search for fiction" autofocus
        @keyup.enter="onSearch"
      >
      <div v-show="input.length" class="absolute right-15 top-2.5" @click="onClear">
        <i-assets-icon-close class="w-4 h-4  text-#86909C" />
      </div>
      <span class="text-14px text-primary font-bold" @click="gotoBack()">
        Cancel
      </span>
    </div>

    <div v-if="skeleton">
      <div class="pt-20vh flex justify-center">
        <BasicSpinner class="mx-auto" />
      </div>
      <p class="mt-4 text-center font-bold">
        Loading...
      </p>
    </div>
    <div v-else-if="error" class="mt-5">
      <BizBlockError :title="error.msg" />
    </div>
    <div v-else-if="!passcode && !result.length">
      <BizBlockEmpty title="No search results" />
    </div>
    <template v-else>
      <div v-if="passcode" class="mt-5">
        <div class="mt-15px flex items-center space-x-15px">
          <img class="w-18 h-25 rounded-3px" :src="passcode.contentCoverUrl" alt="图片:书封">
          <div>
            <p class="text-15px font-bold">
              {{ passcode.contentName }}
            </p>
            <p class="text-13px mt-10px text-#666 line-clamp-2">
              {{ passcode.introduce }}
            </p>
            <a
              class="inline-block mt-1.5 px-10px py-3px text-12px lh-17px text-white rounded-full"
              style="background: linear-gradient(90deg, #FF6E0F 0%, #FF5970 100%);"
              @click="gotoPasscodeBook(passcode)"
            >
              Start Reading
            </a>
          </div>
        </div>
      </div>

      <div v-if="result.length" class="mt-5 -mb-5px">
        <BizBookTrack
          v-for="item in result" :id="uuid()"
          :key="item.id"
          :name="item.contentName"
          :extra="{
            ...item,
          }"
        >
          <router-link
            v-slot="{ navigate }" custom
            :to="{ name: 'book', params: { contentId: item.id } }" replace
          >
            <div class="mt-15px flex items-center space-x-15px" @click="onTrackGotoBook(item.id) && navigate($event)">
              <img class="w-18 h-25 rounded-3px" :src="item.contentCoverUrl" alt="图片:书封">
              <div class="flex-1">
                <p class="text-15px font-bold line-clamp-1">
                  {{ item.contentName }}
                </p>
                <p class="text-13px mt-10px text-#666 line-clamp-2">
                  {{ item.introduce }}
                </p>
                <div class="mt-10px flex justify-between text-13px text-#999">
                  <p>
                    <span>{{ item.finishStatus ? 'Completed' : 'Ongoing' }}</span>
                    <span v-if="item.tagList[0]"> | </span>
                    <span>{{ item.tagList[0]?.name }}</span>
                  </p>
                </div>
              </div>
            </div>
          </router-link>
        </BizBookTrack>
      </div>

      <div v-if="ending" class="py-15px text-13px text-#999 text-center">
        No more ~
      </div>
      <div v-else-if="fetching" class="py-15px flex items-center justify-center space-x-4 text-13px text-#999">
        <div class="animate-spin">
          <i-icon-park-outline-loading />
        </div>
        <p>Loading...</p>
      </div>
    </template>
  </div>
</template>

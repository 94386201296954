import axios from 'axios'
import UAParser from 'ua-parser-js'
import { useSessionStorage } from '@vueuse/core'
import { computed } from 'vue'
import { useUserStore } from '@/stores'

const parser = new UAParser()
/**
 * Mode
 * `debug`: only output to console.warn
 * `realtime`: send track event every time
 */
let mode: 'debug' | 'realtime' = 'debug'
if (import.meta.env.MODE === 'development')
  mode = 'realtime'
else
  mode = 'realtime'

let isInitTrack = false
const payload: Record<string, any> = {
  app: 'h5-novel',
  cm: {
    versionCode: __APP_VERSION__,
    ua: '',
    code: '',
    uid: '',
    equipmentId: '',
    language: '',
    model: '', // 手机型号
    brand: '', // 手机品牌
    hw: '', // heightXwidth,屏幕宽高
  },
  et: [],
}

export function initTrack() {
  const userStore = useUserStore()

  payload.cm.ua = window.navigator.userAgent
  payload.cm.equipmentId = localStorage.getItem('device-uuid')
  payload.cm.language = window.navigator.language
  payload.cm.hw = `${window.innerHeight}X${window.innerWidth}` // heightXwidth,屏幕宽高
  payload.cm.model = parser.getDevice().model // 手机型号
  payload.cm.brand = parser.getDevice().vendor // 手机品牌
  payload.cm.code = userStore.code
  payload.cm.uid = userStore.memberId
  payload.cm.platform = 'H5'

  isInitTrack = true
}

const api = axios.create({
  baseURL: import.meta.env.VITE_TRACK_DOMAIN,
  responseType: 'json',
})

function tracking(et: any) {
  payload.cm.t = Date.now()

  const data = {
    app: payload.app,
    cm: payload.cm,
    et,
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
  if (typeof navigator.sendBeacon === 'function') {
    navigator.sendBeacon(`${import.meta.env.VITE_TRACK_DOMAIN}/tracking/report`, new Blob([JSON.stringify(data)], { type: 'application/json' }))
    return
  }

  api.post('/tracking/report', data)
}

export async function track(name: string, params: Record<string, any>) {
  if (!isInitTrack)
    return

  let et: any = []
  et.push({
    ett: Date.now(),
    en: name,
    kv: params,
  })

  if (mode === 'debug') {
    console.warn(`[track] ${name}: `, { payload, params })
    return
  }

  tracking(et)
  et = []
  payload.et = []
}

let _timer = null
let isTrackingStarted = false
const trackDelay = 3000
let asyncEt = [] as any
export async function asyncTrack(name: string, params: Record<string, any>) {
  if (!isInitTrack)
    return

  asyncEt.push({
    ett: Date.now(),
    en: name,
    kv: params,
  })

  if (mode === 'debug') {
    console.warn(`[track] ${name}: `, { payload, params })
    return
  }

  if (!isTrackingStarted) {
    isTrackingStarted = true
    _timer = setTimeout(async () => {
      tracking(asyncEt)
      asyncEt = []
      isTrackingStarted = false
      _timer = null
    }, trackDelay)
  }
}

// track hooks, 只能在 setup 生命周期中使用
export function useTrack() {
  const historyRecord = useSessionStorage<any[]>('historyRecord', [])
  const routeSource = computed(() => {
    if (historyRecord.value?.length > 1)
      return historyRecord.value[historyRecord.value?.length - 2].path

    return document.referrer
  })

  const currentPageName = computed(() => {
    return historyRecord.value[historyRecord.value?.length - 1].title
  })

  function buttonClickTrack(name: string) {
    track('buttonClick', {
      button_name: name,
      from: currentPageName.value,
      route_source: routeSource.value,
    })
  }

  return {
    routeSource,
    buttonClickTrack,
  }
}

<script lang="ts" setup>
import { onMounted, provide, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { useFocus, useInfiniteScroll } from '@vueuse/core'
import { v4 as uuid } from 'uuid'
import { api } from '@/api'
import { COLUMN_TYPE } from '@/constants'

const router = useRouter()
const route = useRoute()

const pageList = ref<any[]>([])
const pageInfo = ref<any>({})

const skeleton = ref(false)
async function getPageInfo() {
  skeleton.value = true
  try {
    const { data } = await api.post('/page/index')
    if (data.page && data.page.length === 0)
      return

    let isPageId = false
    let pageId: string = Array.isArray(route.query.pageId)
      ? route.query.pageId[0] || ''
      : route.query.pageId || ''
    for (const item of data.page) {
      item.isLoad = false
      if (pageId === item.id)
        isPageId = true

      pageList.value.push(item)
    }
    if (!isPageId)
      pageId = pageList.value[0].id || ''

    if (pageId)
      switchNav(pageId)
  }
  catch (error: any) {
    console.error(error)
  }
  finally {
    skeleton.value = false
  }
}

// Feed
const feeds = ref<any[]>([])
const feedShowName = ref('')
const feedId = ref('')
const feedsColumnType = ref('')
const feedFetching = ref(false)
const feedEnding = ref(false)
const feedError = ref<any>()
function initFeed() {
  const module: any = pageInfo.value.modules.find((item: any) => item.columnType === COLUMN_TYPE.INFO_FLOW)
  if (module) {
    feedShowName.value = module.showName
    feedId.value = module.id
    feedsColumnType.value = module.columnType
    feeds.value = module.resourceList.records
  }
  else {
    feedShowName.value = ''
    feedId.value = ''
    feedsColumnType.value = ''
    feeds.value = []
  }
  feedFetching.value = false
  feedEnding.value = false
  feedError.value = null
}
async function fetchFeed() {
  if (!pageInfo.value?.modules)
    return

  const module: any = pageInfo.value.modules.find((item: any) => item.columnType === COLUMN_TYPE.INFO_FLOW)
  if (!module) {
    feeds.value = []
    return
  }
  if (module.resourceList.current >= module.resourceList.pages) {
    feedEnding.value = true
    return
  }
  feedFetching.value = true
  try {
    const { data } = await api.get<any, any>('/content/getColumnContentList', {
      params: {
        columnId: module.id,
        pageIndex: module.resourceList.current + 1,
        pageSize: module.resourceList.size,
      },
    })
    module.resourceList.current = data.resourceList.current
    module.resourceList.records = module.resourceList.records.concat(data.resourceList.records)
    feeds.value = module.resourceList.records
  }
  catch (error) {
    feedError.value = error
  }
  finally {
    feedFetching.value = false
  }
}

const infiniteRef = ref<HTMLElement>()
provide('pageRef', infiniteRef)
useInfiniteScroll(infiniteRef,
  async () => {
    if (feedFetching.value || feedEnding.value || feedError.value)
      return
    await fetchFeed()
  },
  { distance: 50 },
)

// switch navigation
const modules = ref<any[]>([])
async function switchNav(pageId: string) {
  pageInfo.value = pageList.value.find(item => item.id === pageId)
  if (pageInfo.value.isLoad) {
    modules.value = pageInfo.value.modules
    initFeed()
    return
  }

  try {
    const { data } = await api.get(`/page/pageDetail?pageId=${pageId}`)
    pageInfo.value.modules = (data ?? []).filter((item: any) => {
      return item.resourceList?.records?.length > 0
    })
    modules.value = pageInfo.value.modules
    initFeed()
  }
  catch (error: any) {
    console.error(error)
  }
  finally {
    pageInfo.value.isLoad = true
  }
}

onMounted(() => {
  getPageInfo()
})

const inputRef = ref<HTMLInputElement>()
const input = ref('')
const { focused: inputFocused } = useFocus(inputRef)
function onSearch() {
  router.push({ name: 'search', query: { kw: input.value } })
}

function gotoCollection(id: string) {
  router.push({ name: 'collection', params: { columnId: id } })
}
function gotoBook(id: string) {
  router.push({
    name: 'book',
    params: { contentId: id },
  })
}
</script>

<template>
  <div ref="infiniteRef" class="h-screen overflow-y-scroll">
    <div class="home-bg px-15px pb-20">
      <!-- Tab -->
      <div v-show="!inputFocused" class="py-2.5 overflow-x-auto">
        <div class="flex items-center space-x-8">
          <BizTrackClick v-for="item in pageList" :key="item.id" :name="`tab_${item.showName}`">
            <div
              class="home-tab relative"
              :class="{ active: item.id === pageInfo.id }"
              @click="switchNav(item.id)"
            >
              <span class="text-14px text-#9296A0 whitespace-nowrap">{{ item.showName }}</span>
            </div>
          </BizTrackClick>
        </div>
      </div>
      <!-- Search -->
      <BizTrackClick name="search" class="top-1.5 right-0 transition-all" :class="{ 'w-full! m': inputFocused }">
        <div class="flex items-center space-x-5 relative z-10">
          <i-assets-icon-search class="w-4 h-4 absolute left-3 top-2.5 text-#86909C" />
          <input
            ref="inputRef"
            v-model="input" enterkeyhint="search"
            class="!ml0 flex-1 h-9 w-full pl-9 pr-4 rounded-full border-0 !outline-0 truncate caret-primary !bg-#fff placeholder:text-14px"
            placeholder="Search for fiction"
            @keyup.enter="onSearch"
          >
        </div>
        <div v-if="inputFocused" class="fixed inset-0 bg-white w-full h-100vh" />
      </BizTrackClick>
      <!-- Modules -->
      <div v-if="skeleton" class="h-screen">
        <div class="pt-20vh flex justify-center">
          <BasicSpinner class="mx-auto" />
        </div>
        <p class="mt-4 text-center font-bold">
          Loading...
        </p>
      </div>
      <div v-else>
        <div v-for="col in modules" :key="col.id" class="mt-3.75">
          <template v-if="col.columnType === COLUMN_TYPE.SLIDE_BOOK">
            <div id="test" class="mt-3.75 px-3.75 py-5 bg-white rounded overflow-hidden">
              <div class="-mb-5px flex items-baseline justify-between">
                <p class="text-17px font-bold text-black">
                  {{ col.showName }}
                </p>
                <BizTrackClick :name="`see_all_${col.showName}`">
                  <p class="text-13px text-#999 flex items-center" @click="gotoCollection(col.id)">
                    <span>See All</span>
                    <i-icon-park-outline-right />
                  </p>
                </BizTrackClick>
              </div>
              <div class="mt-15px overflow-x-auto">
                <div class="flex">
                  <div v-for="(item, index) in col.resourceList.records" :key="item.id" :class="{ 'ml-9px': index !== 0 }">
                    <BizBookTrack
                      :id="uuid()"
                      :name="item.contentName"
                      :extra="{
                        module_name: col.showName,
                        module_id: col.id,
                        module_type: col.columnType,
                        pageId: pageInfo.id,
                        ...item,
                      }"
                    >
                      <div @click="gotoBook(item.id)">
                        <img class="h-28 rounded-3px calculate-width-1" :src="item.contentCoverUrl">
                        <p class="mt-10px text-14px line-clamp-2 calculate-width-1">
                          {{ item.contentName }}
                        </p>
                      </div>
                    </BizBookTrack>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="col.columnType === COLUMN_TYPE.ROW_1_PLUS_COL_4">
            <div class="px-3.75 py-5 bg-white rounded">
              <div class="flex items-baseline justify-between -mb-10px">
                <p class="text-17px font-bold text-black">
                  {{ col.showName }}
                </p>
                <BizTrackClick :name="`see_all_${col.showName}`">
                  <p class="text-13px text-#999 flex items-center" @click="gotoCollection(col.id)">
                    <span>See All</span>
                    <i-icon-park-outline-right />
                  </p>
                </BizTrackClick>
              </div>
              <BizBookTrack
                v-if="col.resourceList.records[0]"
                :id="uuid()"
                :extra="{
                  module_name: col.showName,
                  module_id: col.id,
                  module_type: col.columnType,
                  pageId: pageInfo.id,
                  ...col.resourceList.records[0],
                }"
              >
                <div
                  class="mt-5 flex items-center space-x-15px"
                  @click="gotoBook(col.resourceList.records[0].id)"
                >
                  <img class="h-25 rounded-3px calculate-width-2" :src="col.resourceList.records[0].contentCoverUrl" alt="xxx">
                  <div>
                    <p class="text-15px font-bold line-clamp-1">
                      {{ col.resourceList.records[0].contentName }}
                    </p>
                    <p class="text-13px mt-10px text-#666 line-clamp-2">
                      {{ col.resourceList.records[0].introduce }}
                    </p>
                    <p v-if="col.resourceList.records[0].tagList.length" class="mt-10px space-x-5px flex flex-wrap h-24px overflow-hidden">
                      <span
                        v-for="tag in col.resourceList.records[0].tagList" :key="tag.id"
                        class="px-2 py-0.5 text-12px text-#65686F bg-#F3F5FA rounded-14px"
                      >
                        {{ tag.name }}
                      </span>
                    </p>
                  </div>
                </div>
              </BizBookTrack>
              <div class="mt-5 grid grid-cols-4 space-x-9px">
                <div v-for="item in col.resourceList.records.slice(1, 5)" :key="item.id">
                  <BizBookTrack
                    :id="uuid()"
                    :name="item.contentName"
                    :extra="{
                      module_name: col.showName,
                      module_id: col.id,
                      module_type: col.columnType,
                      pageId: pageInfo.id,
                      ...item,
                    }"
                  >
                    <div
                      @click="gotoBook(item.id)"
                    >
                      <img class="h-25 rounded-3px calculate-width-2" :src="item.contentCoverUrl">
                      <p class="mt-10px text-14px calculate-width-2 line-clamp-2">
                        {{ item.contentName }}
                      </p>
                    </div>
                  </BizBookTrack>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="col.columnType === COLUMN_TYPE.ROW_2_MUL_COL_4">
            <div class="px-3.75 py-5 bg-white rounded">
              <div class="flex items-baseline justify-between -mb-10px">
                <p class="text-17px font-bold text-black">
                  {{ col.showName }}
                </p>
                <BizTrackClick :name="`see_all_${col.showName}`">
                  <p class="text-13px text-#999 flex items-center" @click="gotoCollection(col.id)">
                    <span>See All</span>
                    <i-icon-park-outline-right />
                  </p>
                </BizTrackClick>
              </div>
              <div class="mt-6 grid grid-cols-4 space-x-9px">
                <div v-for="item in col.resourceList.records.slice(0, 4)" :key="item.id">
                  <BizBookTrack
                    :id="uuid()"
                    :name="item.contentName"
                    :extra="{
                      module_name: col.showName,
                      module_id: col.id,
                      module_type: col.columnType,
                      pageId: pageInfo.id,
                      ...item,
                    }"
                  >
                    <div
                      @click="gotoBook(item.id)"
                    >
                      <img class="h-25 rounded-3px calculate-width-2" :src="item.contentCoverUrl">
                      <p class="mt-10px text-14px calculate-width-3 line-clamp-2">
                        {{ item.contentName }}
                      </p>
                    </div>
                  </BizBookTrack>
                </div>
              </div>
              <div class="mt-5 grid grid-cols-4 space-x-9px">
                <div v-for="item in col.resourceList.records.slice(4, 8)" :key="item.id">
                  <BizBookTrack
                    :id="uuid()"
                    :name="item.contentName"
                    :extra="{
                      module_name: col.showName,
                      module_id: col.id,
                      module_type: col.columnType,
                      pageId: pageInfo.id,
                      ...item,
                    }"
                  >
                    <div
                      @click="gotoBook(item.id)"
                    >
                      <img class="h-25 rounded-3px calculate-width-2" :src="item.contentCoverUrl">
                      <p class="mt-10px text-14px calculate-width-3 line-clamp-2">
                        {{ item.contentName }}
                      </p>
                    </div>
                  </BizBookTrack>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="col.columnType === COLUMN_TYPE.ROW_1_MUL_COL_4">
            <div class="mt-3.75 px-3.75 py-5 bg-white rounded overflow-hidden">
              <div class="-mb-5px flex items-baseline justify-between">
                <p class="text-17px font-bold text-black">
                  {{ col.showName }}
                </p>
                <BizTrackClick :name="`see_all_${col.showName}`">
                  <p class="text-13px text-#999 flex items-center" @click="gotoCollection(col.id)">
                    <span>See All</span>
                    <i-icon-park-outline-right />
                  </p>
                </BizTrackClick>
              </div>
              <div class="mt-15px grid grid-cols-4 space-x-9px">
                <div v-for="item in col.resourceList.records.slice(0, 4)" :key="item.id">
                  <BizBookTrack
                    :id="uuid()"
                    :name="item.contentName"
                    :extra="{
                      module_name: col.showName,
                      module_id: col.id,
                      module_type: col.columnType,
                      pageId: pageInfo.id,
                      ...item,
                    }"
                  >
                    <div @click="gotoBook(item.id)">
                      <img class="h-25 rounded-3px calculate-width-2" :src="item.contentCoverUrl">
                      <p class="mt-10px text-14px line-clamp-2 calculate-width-3">
                        {{ item.contentName }}
                      </p>
                    </div>
                  </BizBookTrack>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <!-- Feed -->
      <div v-if="feeds.length" class="mt-3.75 px-3.75 py-5 bg-white rounded">
        <div class="flex items-baseline justify-between -mb-10px">
          <p class="text-17px font-bold text-black">
            {{ feedShowName }}
          </p>
        </div>

        <div v-for="(item, index) in feeds" :key="index">
          <BizBookTrack
            :id="uuid()"
            :name="item.contentName"
            :extra="{
              module_name: feedShowName,
              module_id: feedId,
              module_type: feedsColumnType,
              pageId: pageInfo.id,
              ...item,
            }"
          >
            <div class="mt-5 flex items-center space-x-15px" @click="gotoBook(item.id)">
              <img class="w-18 h-25 rounded-3px" :src="item.contentCoverUrl">
              <div class="flex-1">
                <p class="text-15px font-bold line-clamp-1">
                  {{ item.contentName?.replace(/&nbsp;/g, ' ') ?? '' }}
                </p>
                <p class="text-13px mt-10px text-#666 line-clamp-2 break-anywhere">
                  {{ item.introduce }}
                </p>
                <div class="mt-10px flex justify-between text-13px text-#999">
                  <p>
                    <span>{{ item.finishStatus ? 'Completed' : 'Ongoing' }}</span>
                    <span v-if="item.tagList[0]"> | </span>
                    <span>{{ item.tagList[0]?.name }}</span>
                  </p>
                </div>
              </div>
            </div>
          </BizBookTrack>
        </div>
      </div>
      <div v-if="feedEnding" class="py-15px text-13px text-#999 text-center">
        No more ~
      </div>
      <div v-else-if="feedFetching" class="py-15px flex items-center justify-center space-x-4 text-13px text-#999">
        <div class="animate-spin">
          <i-icon-park-outline-loading />
        </div>
        <p>Loading...</p>
      </div>
    </div>

    <BasicTabbar v-show="!inputFocused" />
  </div>
</template>

<style lang="scss" scoped>
.home-bg {
  background: url('@/assets/home-bg.png') no-repeat top left;
  background-size: 100% auto;
}
.home-tab {
  cursor: pointer;
}
.home-tab.active {
  span {
    position: relative;
    line-height: 20px;
    font-size: 20px;
    color: #323435;
    font-weight: bold;
    z-index: 1;
  }
  i {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom: 2px;
    width: 100%;
    height: 6px;
    background-image: linear-gradient(270deg, #F6F7F9 0%, #9875FB 95%);
    border-radius: 3px;
    z-index: 0;
  }
}
.calculate-width-1 {
  width: calc((100vw - 60px) / 4 + 3px);
}
.calculate-width-2 {
  width: calc((100vw - 60px) / 4);
}
.calculate-width-3 {
  width: calc((100vw - 60px) / 4 - 5px);
}
</style>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { api } from '@/api'
import { useToastStore, useUserStore } from '@/stores'
import { login } from '@/fb'
import { $formatErrorMessage } from '@/helpers'

const userStore = useUserStore()
const appVersion = __APP_VERSION__

const user = useUserStore()
const { $toast } = useToastStore()
const router = useRouter()
const tapTimes = ref(0)
// Note: 调试入口, 1秒内连续点击击 5 次, 进入

let timerId: any
function onDebugEnter() {
  tapTimes.value++
  if (tapTimes.value === 5) {
    tapTimes.value = 0
    router.push({ name: 'debug' })
    $toast('进入调试页面')
  }
  if (timerId)
    return
  timerId = setTimeout(() => {
    tapTimes.value = 0
  }, 1000)
}
const thirdAuthVisible = ref(false)
function onThirdAuth() {
  thirdAuthVisible.value = false
  login().then(async (res: any) => {
    try {
      const { data } = await api.post<any>('/member/bind/facebook', {
        thirdPartId: res.id,
        nickName: res.name,
      })
      localStorage.setItem('token', data.token)
      await user.init({ force: true })
    }
    catch (error) {
      $toast($formatErrorMessage(error))
    }
  }).catch((error) => {
    $toast(error.msg || 'Network error')
  })
}

onMounted(() => {
  userStore.init({ force: true })
})
</script>

<template>
  <div class="cover">
    <div class="px-30px pt-40px pb-27px flex items-center justify-between">
      <div class="flex items-center space-x-14px">
        <img v-if="userStore.$state.thirdHeadImgUrl" class="w-15 h-15 border border-#F5F2FF rounded-full" :src="userStore.$state.thirdHeadImgUrl" alt="avatar">
        <img v-else class="w-15 h-15 rounded-full" src="@/assets/user/user-default.png" alt="avatar">
        <div>
          <p class="text-18px text-#fff">
            {{ userStore.$state.thirdNickName || userStore.$state.memberName }}
          </p>
          <p class="text-11px text-#fff">
            UID {{ userStore.$state.code }}
          </p>
          <p v-if="userStore.$state.subscribe" class="flex flex-items-center mt-10px">
            <img src="@/assets/member-vip.png" class="w-42px h-14px">
            <span class="color-#fff text-12px ml-4px">Expires {{ userStore.$state.subscribeEndDate }}</span>
          </p>
        </div>
      </div>
      <!-- <BizTrackClick v-if="!userStore.$state.thirdPartId" name="Sign In" type="link">
        <a class="px-14px py-7px text-#9875FB text-13px font-bold rounded-full bg-#EDE7FD border border-#fff" @click="thirdAuthVisible = true">
          Sign In
        </a>
      </BizTrackClick> -->
    </div>
    <div class="mx-10px px-20px h-60px bg-#fff b-rd-2 flex justify-between">
      <div class="flex items-center">
        <i-assets-icon-wallet />
        <strong class="ml-12px">My Wallet</strong>
      </div>
      <div class="flex items-center">
        <strong>{{ userStore.$state.coinBalance }}</strong>
        <span class="ml-5px text-12px text-#65686F">coins</span>
        <BizTrackClick name="Store">
          <router-link
            :to="{ name: 'pay' }"
            class="
            ml-10px px-14px py-7px text-#fff text-13px font-bold rounded-full
            bg-#9875FB
          "
          >
            Top Up
          </router-link>
        </BizTrackClick>
      </div>
    </div>
    <div class="mt-10px mx-10px px-20px bg-white b-rd-2 box-shadow">
      <BizTrackClick name="transaction_history">
        <router-link
          :to="{ name: 'recharge' }"
          class="w-full h-61px flex items-center justify-between border-#F3F5FA"
        >
          <div class="flex items-center space-x-7px">
            <i-assets-icon-transaction />
            <p class="text-15px font-bold">
              Transaction History
            </p>
          </div>
          <i-icon-park-outline-right class="text-#999" />
        </router-link>
      </BizTrackClick>
      <BizTrackClick name="chapters_unlocked">
        <router-link
          :to="{ name: 'consume' }"
          class="w-full h-61px flex items-center justify-between border-t border-#F3F5FA"
        >
          <div class="flex items-center space-x-7px">
            <i-assets-icon-unlocked />
            <p class="text-15px font-bold">
              Chapters Unlocked
            </p>
          </div>
          <i-icon-park-outline-right class="text-#999" />
        </router-link>
      </BizTrackClick>
      <BizTrackClick name="contact_us">
        <router-link
          :to="{ name: 'contact-us' }"
          class="w-full h-61px flex items-center justify-between border-t border-#F3F5FA"
        >
          <div class="flex items-center space-x-7px">
            <i-assets-icon-contact-us />
            <p class="text-15px font-bold">
              Contact us
            </p>
          </div>
          <i-icon-park-outline-right class="text-#999" />
        </router-link>
      </BizTrackClick>
      <BizTrackClick name="setting">
        <router-link
          :to="{ name: 'setting' }"
          class="w-full h-61px flex items-center justify-between border-t border-#F3F5FA"
        >
          <div class="flex items-center space-x-7px">
            <i-assets-icon-setting />
            <p class="text-15px font-bold">
              Setting
            </p>
          </div>
          <i-icon-park-outline-right class="text-#999" />
        </router-link>
      </BizTrackClick>
    </div>

    <div class="mt-20 flex justify-center text-xs text-#999">
      <p @click="onDebugEnter">
        version: {{ appVersion }}
      </p>
    </div>

    <BasicTabbar tab="member" />

    <BasicModal v-model="thirdAuthVisible">
      <div class="p-5">
        <a class="flex items-center justify-center h-44px b-rd-3px bg-#4c69ba text-#fff" @click="onThirdAuth">
          <img class="_55at img" src="https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/FehCVG1ix9z.png" alt="" width="18" height="18">
          <span class="ml-6px">Login with Facebook</span>
        </a>
      </div>
    </BasicModal>
  </div>
</template>

<style scoped>
.cover {
  width: 100%;
  background: url('@/assets/user/user-bg.png') no-repeat top left;
  background-size: 100% auto;
}
</style>

<script lang="ts" setup>
import { useSessionStorage } from '@vueuse/core'
import { computed } from 'vue'
import { track } from '@/track'

const props = defineProps<{
  name: string
  extra?: Record<string, any>
}>()

const historyRecord = useSessionStorage<any[]>('historyRecord', [])

const routeSource = computed(() => {
  if (historyRecord.value?.length > 1)
    return historyRecord.value[historyRecord.value?.length - 2].path

  return document.referrer
})

const currentPageName = computed(() => {
  return historyRecord.value[historyRecord.value?.length - 1].title
})

function onClick() {
  track('buttonClick', {
    button_name: props.name,
    from: currentPageName.value,
    route_source: routeSource.value,
    ...props.extra,
  })
}
</script>

<template>
  <div @click.capture="onClick">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useInfiniteScroll } from '@vueuse/core'
import moment from 'moment'
import { api } from '@/api'
import { RECHARGE_TYPE_NAME } from '@/constants'

const records = ref<any[]>([])
const page = ref(1)
const fetching = ref(false)
const ending = ref(false)
const error = ref<any>()
const pagination = computed(() => ({
  pageIndex: page.value,
  pageSize: 20,
}))
async function fetchFeed() {
  fetching.value = true
  try {
    const { data } = await api.get<any>('/member/topup/list', { params: { ...pagination.value } })
    records.value = [...records.value, ...data.records]
    if (page.value >= data.pages)
      ending.value = true
    else
      page.value += 1
  }
  catch (_error) {
    error.value = _error
  }
  finally {
    fetching.value = false
  }
}
const infiniteRef = ref<HTMLElement>()
useInfiniteScroll(infiniteRef,
  async () => {
    if (fetching.value || ending.value || error.value)
      return
    await fetchFeed()
  },
  { distance: 50 },
)
</script>

<template>
  <div ref="infiniteRef" class="h-screen overflow-scroll bg-white">
    <BasicNavbar />

    <div v-if="!fetching && page === 1 && records.length === 0" class="mt-11 pt-15vh w-full text-center flex flex-col justify-center">
      <BizBlockEmpty title="No record" />
    </div>
    <div v-else class="pt-44px px-5">
      <div v-for="item in records" :key="item.orderId" class="h-77px flex justify-between items-center border-b">
        <div>
          <h3>{{ item.coins }} Coins</h3>
          <span class="text-12px text-#9296A0">{{ moment(item.rechargeTime).format('LL') }}</span>
        </div>
        <div class="text-right">
          <h3>{{ RECHARGE_TYPE_NAME[item.rechargeType] }}</h3>
          <span v-if="item.coinAccountType === 2" class="text-12px text-#9296A0">Expire on {{ moment(item.expireTime).format('LL') }}</span>
        </div>
      </div>
    </div>
    <div v-if="ending && records.length" class="py-15px text-13px text-#999 text-center">
      No more ~
    </div>
    <div v-else-if="fetching" class="py-15px flex items-center justify-center space-x-4 text-13px text-#999">
      <div class="animate-spin">
        <i-icon-park-outline-loading />
      </div>
      <p>Loading...</p>
    </div>
  </div>
</template>

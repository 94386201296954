<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { api } from '@/api'
import { useLoadingStore, useToastStore, useUserStore } from '@/stores'
import { login } from '@/fb'
import { $formatErrorMessage } from '@/helpers'
import { SubscriptionCycle } from '@/utils/enum'
import { track, useTrack } from '@/track'

const props = withDefaults(
  defineProps<{
    type?: number
    contentId?: string
    contentChapterId?: string
    bookName?: string
    chapterName?: string
    serialStatus?: number
  }>(),
  {
    type: 2,
    contentId: '',
    contentChapterId: '',
    bookName: '',
    chapterName: '',
    serialStatus: 0,
  },
)
const emit = defineEmits(['getPayUrlSuccess'])
const { $showLoading, $hideLoading } = useLoadingStore()
const userStore = useUserStore()
const { $toast } = useToastStore()

const templateProducts = ref<any>([])
const subscribeProducts = ref<any>([])
const data = ref<any>()
const memberLayerId = ref<string>('')
onMounted(async () => {
  try {
    const params: any = {
      type: props.type,
    }
    if (props.type === 1) {
      params.contentId = props.contentId
      params.contentChapterId = props.contentChapterId
    }
    const res: any = await api.get<any>('/member/topup/product/list', {
      params,
    })
    memberLayerId.value = res?.memberLayerId || ''
    data.value = res?.data
    templateProducts.value = res.data || []
    // subscribeProducts.value = res.data?.subscribeProducts || []

    trackPurchase()
  }
  catch (error) {
    $toast($formatErrorMessage(error))
  }
})

function trackPurchase() {
  const { routeSource } = useTrack()

  const common = {
    is_first_purchase: (userStore.$state.rechargeCount || 0) > 0 ? 0 : 1,
    purchase_id: templateProducts.value[0]?.payConfigId || '',
    purchase_name: templateProducts.value[0]?.payConfigName || '',
    currency_coins: userStore.$state.coinBalance,
    layer_id: memberLayerId.value || '',
    route_source: routeSource.value,
  }

  if (props.type === 1) {
    track('purchasePopShow', {
      book_id: props.contentId,
      chapter_id: props.contentChapterId,
      book_name: props.bookName,
      chapter_name: props.chapterName,
      serial_status: props.serialStatus === 1 ? 'completed' : 'ongoing',
      ...common,
    })
    return
  }

  return track('purchaseCenterShow', common)
}
const thirdAuthVisible = ref(false)
const templateCache = ref<any>(null)
async function onClickPayTemplate(item: any) {
  templateCache.value = item

  $showLoading()

  const url = `${window.location.origin}/paying?redirect_source=${encodeURIComponent(window.location.href)}`
  const params = {
    payConfigId: item.payConfigId,
    productId: item.productId,
    contentId: props.contentId,
    contentChapterId: props.contentChapterId,
    memberCurrencyCode: 'usd',
    payType: 6,
    type: 2,
    successUrl: `${url}&payment_status=success`,
    cancelUrl: `${url}&payment_status=cancel`,
  }

  try {
    const { data } = await api.post<any>('/member/topup/createPaypalPaymentIntent', params)
    emit('getPayUrlSuccess', data)
    window.location.href = data
  }
  catch (error) {
    $toast($formatErrorMessage(error))
  }
  $hideLoading()
}

async function onSubscribeProducts(item: any) {
  $showLoading()
  const url = `${window.location.origin}/paying?redirect_source=${encodeURIComponent(window.location.href)}`
  const params = {
    payConfigId: item.payConfigId,
    productId: item.productId,
    contentId: props.contentId,
    contentChapterId: props.contentChapterId,
    memberCurrencyCode: 'usd',
    subscribeConfigId: item.subscribeConfigId,
    payType: 6,
    type: 2,
    successUrl: `${url}&payment_status=success&type=subscribe`,
    cancelUrl: `${url}&payment_status=cancel&type=subscribe`,
  }

  try {
    const { data } = await api.post<PostMemberSubscribeCreateOrderQuery, PostMemberSubscribeCreateOrderResponse>('/member/subscribe/createOrder', params)
    window.location.href = data
  }
  catch (error) {
    $toast($formatErrorMessage(error))
    $hideLoading()
  }
}

function getUnitPrice(item: any) {
  if (!item.cycle)
    return false

  if (item.cycle === SubscriptionCycle.YEAR)
    return `${(Number(item.price) / 12).toFixed(2)}/month`

  if (item.cycle === SubscriptionCycle.HALF_YEAR)
    return `${(Number(item.price) / 6).toFixed(2)}/month`

  if (item.cycle === SubscriptionCycle.MONTH)
    return `${(Number(item.price) / 30).toFixed(2)}/day`

  if (item.cycle === SubscriptionCycle.WEEK)
    return `${(Number(item.price) / 7).toFixed(2)}/day`

  if (item.cycle === SubscriptionCycle.DAY)
    return `${Number(item.price).toFixed(2)}/day`

  return '-'
}

function onThirdAuth() {
  thirdAuthVisible.value = false
  login().then(async (res: any) => {
    try {
      const { data } = await api.post<any>('/member/bind/facebook', {
        thirdPartId: res.id,
        nickName: res.name,
        email: res.email,
        headImage: res.picture?.data?.url ?? '',
      })
      localStorage.setItem('token', data.token)
      await userStore.init({ force: true })
    }
    catch (error) {
      $toast($formatErrorMessage(error))
    }
  }).catch((error) => {
    $toast(error.msg || 'Network error')
  })
}
</script>

<template>
  <div>
    <div class="pay-template pt-2px pb-12px">
      <!-- 充值 -->
      <div v-for="(item) in templateProducts" :key="item.productId" class="pay-template-info flex items-center space-x-15px mt-10px mx-10px px-16px py-16px b-rd-2 bg-#F3F5FA border border-color-#F3F5FA" @click="onClickPayTemplate(item)">
        <div>
          <img class="w-24px h-24px" src="@/assets/welfare/welfare-gold.png">
        </div>
        <div class="flex-1">
          <div>
            <strong class="text-18px">{{ item.coinsBuy }}</strong>
            <span class="ml-5px text-14px text-#65686F">coins</span>
          </div>
          <div class="flex items-center">
            <span class="text-14px text-#9875FB">+{{ item.coinsBonus }} coins</span>
            <i v-if="item.marketingText" class="inline-block ml-10px px-9px bg-#F74984 b-rd-12px text-#fff text-10px font-not-italic">{{ item.marketingText }}</i>
          </div>
        </div>
        <div class="text-right">
          <strong class="text-18px">$ {{ item.price }}</strong>
          <p v-if="item.cornerTip" class="text-12px text-#65686F">
            +{{ parseFloat((item.coinsBonus / item.coinsBuy * 100).toFixed(2)) }}% coins
          </p>
        </div>
      </div>
      <!-- 订阅 -->
      <div v-for="(item) in subscribeProducts" :key="item.productId" class="pay-template-info flex items-center space-x-15px mt-10px mx-10px px-16px py-16px b-rd-2 bg-#F3F5FA border border-color-#F3F5FA" @click="onSubscribeProducts(item)">
        <div>
          <img class="w-24px h-24px" src="@/assets/welfare/welfare-gold.png">
        </div>
        <div class="flex-1">
          <div>
            <strong class="text-18px">{{ SubscriptionCycle.getItemByValue(item.cycle)?.label }}</strong>
          </div>
          <div class="flex items-center">
            <span class="text-14px text-#9875FB">{{ item.marketingCopy }}</span>
            <i v-if="item.marketingText" class="inline-block ml-10px px-9px bg-#F74984 b-rd-12px text-#fff text-10px font-not-italic">{{ item.marketingText }}</i>
          </div>
        </div>
        <div class="text-right">
          <strong class="text-18px">$ {{ item.price }}</strong>
          <p class="text-12px text-#65686F">
            $ {{ getUnitPrice(item) }}
          </p>
        </div>
      </div>
    </div>
    <BasicModal v-model="thirdAuthVisible">
      <div class="p-5">
        <a class="flex items-center justify-center h-44px b-rd-3px bg-#4c69ba text-#fff" @click="onThirdAuth">
          <img class="_55at img" src="https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/FehCVG1ix9z.png" alt="" width="18" height="18">
          <span class="ml-6px">Login with Facebook</span>
        </a>
      </div>
    </BasicModal>
  </div>
</template>

<style lang="scss" scoped>
.pay-template-info:hover {
  border: 1px solid #9875FB;
  background-color: #F3EFFF;
}
</style>

<script lang="ts" setup>
import { ref } from 'vue'
import { $getToken } from '@/helpers'

const iframeUrl = ref(`https://h5.rocnovel.com/#/issue?token=${$getToken()}&platform=3`)
</script>

<template>
  <div class="bg-#fff">
    <div class="h-44px">
      <BasicNavbar opacity />
    </div>
    <iframe :src="iframeUrl" frameborder="0" class="w-full h-100vh" />
  </div>
</template>

import { type RouteConfig } from 'vue-router'
import Debug from '@/pages/debug.vue'
import Home from '@/pages/home.vue'
import Search from '@/pages/search.vue'
import History from '@/pages/history.vue'
import Member from '@/pages/member.vue'
import ContactUs from '@/pages/contact-us.vue'
import Setting from '@/pages/setting.vue'
import Pay from '@/pages/pay.vue'
import Paying from '@/pages/paying.vue'
import Consume from '@/pages/consume.vue'
import Recharge from '@/pages/recharge.vue'
import Book from '@/pages/book.vue'
import Chapter from '@/pages/chapter.vue'
import Welfare from '@/pages/welfare.vue'
import Collection from '@/pages/collection.vue'
import Go from '@/pages/go.vue'
import Error from '@/pages/error.vue'
import NotFound from '@/pages/404.vue'

const routes: Array<RouteConfig> = [
  { path: '/debug', name: 'debug', component: Debug, meta: { title: 'DEBUG', requiresAuth: false } },
  { path: '/', name: 'home', component: Home, meta: { title: 'Novel', trackTitle: 'index_novel' }, props: true },
  { path: '/search', name: 'search', component: Search, meta: { title: 'Search' } },
  { path: '/history', name: 'history', component: History, meta: { title: '最近阅读', trackTitle: 'index_library' } },
  { path: '/member', name: 'member', component: Member, meta: { title: 'Profile', trackTitle: 'index_profile' } },
  { path: '/contact-us', name: 'contact-us', component: ContactUs, meta: { title: 'Contact us', trackTitle: 'contact_us' } },
  { path: '/setting', name: 'setting', component: Setting, meta: { title: 'Setting', trackTitle: 'setting' } },
  { path: '/recharge', name: 'recharge', component: Recharge, meta: { title: 'Transaction History', trackTitle: 'transaction_history' } },
  { path: '/consume', name: 'consume', component: Consume, meta: { title: 'Chapters UnLocked', trackTitle: 'chapter_unlock' } },
  { path: '/pay', name: 'pay', component: Pay, meta: { title: 'Store', trackTitle: 'store' } },
  { path: '/content/:contentId', name: 'book', component: Book, meta: { title: 'Detail', trackTitle: '书首页' }, props: true },
  { path: '/content/:contentId/:chapterId', name: 'chapter', component: Chapter, meta: { title: 'Chapter Detail' }, props: true },
  { path: '/welfare', name: 'welfare', component: Welfare, meta: { title: 'Rewards', trackTitle: 'index_reward' } },
  { path: '/collection/:columnId', name: 'collection', component: Collection, meta: { title: '专栏' }, props: true },
  { path: '/paying', name: 'paying', component: Paying, meta: { title: 'Payment' }, props: route => ({ code: route.query.code, productId: route.query.productId }) },
  { path: '/go', name: 'go', component: Go, meta: { title: '跳转中' }, props: route => ({ type: route.query.type }) },
  { path: '/500', name: 'error', component: Error, meta: { title: '服务器错误', requiresAuth: false }, props: route => ({ level: route.query.level }) },
  { path: '*', name: 'notFound', alias: '/404', component: NotFound, meta: { title: '404', requiresAuth: false } },
]

export default routes

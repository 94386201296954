<script lang="ts" setup>
import { useRouter } from 'vue-router/composables'
import { onMounted, ref, watch } from 'vue'
import { api } from '@/api'
import { track } from '@/track'
import { useUserStore } from '@/stores'

const props = defineProps<{
  bookId: string
}>()

const emit = defineEmits(['error', 'seeAll', 'read'])

type Data = GetContentGetcolumncontentlistResponse['data']

const userStore = useUserStore()
const router = useRouter()
const recommendError = ref<boolean>(false)
const fetching = ref(false)
const books = ref<Data['resourceList']['records']>([])
const all = ref<Data | null>(null)
const columnId = ref('')

const pagination = {
  pageIndex: 1,
  pageSize: 4,
}

function gotoBook(id: string) {
  emit('read')
  router.push({
    name: 'book',
    params: { contentId: id },
  })

  track('RecmodClick', {
    book_id: props.bookId || '',
    recommend_book_id: id,
    is_first_purchase: (userStore.$state.rechargeCount || 0) > 0 ? 0 : 1,
  })
}

function gotoCollection(id: string) {
  emit('seeAll')
  router.push({ name: 'collection', params: { columnId: id } })

  track('RecmodClick', {
    book_id: props.bookId || '',
    recommend_book_id: 'see_all',
    is_first_purchase: (userStore.$state.rechargeCount || 0) > 0 ? 0 : 1,
  })
}

async function fetchCollection() {
  fetching.value = true
  try {
    const { code, data } = await api.get<any, GetContentGetcolumncontentlistResponse>('/content/getLikeColumn', { params: { contentId: props.bookId, ...pagination } })
    if (code !== 0 || data.resourceList?.records?.length < 4) {
      recommendError.value = true
      return
    }
    all.value = data
    books.value = data.resourceList.records
    columnId.value = data.id!
  }
  catch (_error) {
    recommendError.value = true
  }
  finally {
    fetching.value = false
  }
}

watch(
  () => recommendError.value,
  () => {
    emit('error')
  },
)

onMounted(async () => {
  await fetchCollection()

  track('CloseUnlock', {
    book_id: props.bookId || '',
    is_first_purchase: (userStore.$state.rechargeCount || 0) > 0 ? 0 : 1,
    recommend_book_ids: books.value.map(item => item.id).join(','),
  })
})
</script>

<template>
  <div>
    <div class="flex justify-between items-center mb-18px">
      <div class="text-22px font-medium">
        You Might Also Like
      </div>
      <BizTrackClick name="`see_all_recommend`">
        <p class="text-13px text-#999 flex items-center" @click="gotoCollection(String(columnId))">
          <span>See All</span>
          <i-icon-park-outline-right />
        </p>
      </BizTrackClick>
    </div>
    <div class="flex justify-between">
      <div v-for="(item, index) in books" :key="index" @click="gotoBook(item.id || '')">
        <img class="h-25 rounded-8px calculate-width-2" :src="item.contentCoverUrl">
        <p class="mt-10px text-14px calculate-width-2 line-clamp-2">
          {{ item.contentName }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.calculate-width-2 {
  width: calc((100vw - 80px) / 4);
}
</style>

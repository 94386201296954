<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router/composables'
import { api } from '@/api'
import { useToastStore } from '@/stores'

const route = useRoute()
const { $toast } = useToastStore()

const orderId = route.query.subscription_id || route.query.orderId
const token = route.query.token
const pageType = route.query.type
const payment_status = route.query.payment_status as string
let redirect_source = route.query.redirect_source as string
if (redirect_source && typeof redirect_source === 'string')
  redirect_source = window.decodeURIComponent(redirect_source)
else
  redirect_source = window.location.origin

let pollingTimer: any = null
let pollingCount = 0
const loadingVisible = ref(true)

onMounted(async () => {
  // 从订阅过来
  if (pageType === 'subscribe' && payment_status === 'success') {
    paymentRedirect('Payment success')
    return
  }

  if (orderId && payment_status === 'success') {
    requestPayStatus()
    payStatusPolling()
  }
  else {
    paymentRedirect('Payment failure')
  }
})

function paymentRedirect(text: string) {
  // loadingVisible.value = false
  $toast(text)
  setTimeout(() => {
    let url
    if (payment_status === 'success') {
      url = new URL(redirect_source)
      url.searchParams.set('payment_status', 'success')
    }
    else {
      url = redirect_source
    }
    window.location.replace(url)
  }, 1500)
}

async function requestPayStatus() {
  try {
    const { data } = await api.post(`/member/topup/verifyReceipt?orderNum=${orderId}&receipt=${token}`)
    if (data) {
      $toast('Payment success')
      paymentRedirect('Payment success')
      pollingTimer && clearInterval(pollingTimer)
    }
  }
  catch (error) {
    pollingTimer && clearInterval(pollingTimer)
    paymentRedirect('Payment failure')
  }
}
function payStatusPolling() {
  pollingTimer = setInterval(async () => {
    requestPayStatus()
    pollingCount++
    if (pollingCount > 15) {
      pollingTimer && clearInterval(pollingTimer)
      paymentRedirect('Payment failure')
    }
  }, 2000)
}
</script>

<template>
  <div>
    <div v-if="loadingVisible" class="w-full h-screen bg-white">
      <div class="pt-20vh flex justify-center">
        <BasicSpinner class="mx-auto" />
      </div>
      <p class="mt-8 font-bold text-center">
        <span>{{ 'Recharge please wait' }}</span>
      </p>
    </div>
  </div>
</template>

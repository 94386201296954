import VueRouter from 'vue-router'
import { v4 as uuid } from 'uuid'
import useFacebookTrack from '../src/composables/useFacebookTrack'
import { ERROR_PAGE_LEVEL } from './constants'
import { useConfigStore, useHistoryStore, useQueryStore, useUserStore } from '@/stores'
import routes from '@/routes'
import './ployfills'

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(_to, _from, _savedPosition) {
    return { x: 0, y: 0, behavior: 'smooth' }
  },
})

// Router Guards
router.beforeEach(async (to, form, next) => {
  // Set document title
  document.title = to?.meta?.title

  // Init query store
  const queryStore = useQueryStore()
  queryStore.init(to.query)

  const configStore = useConfigStore()
  const userStore = useUserStore()

  window.entryUrl = window.entryUrl || window.location.href

  // Update document title
  if (configStore.$state.wxAppName)
    document.title = configStore.$state.wxAppName

  if (to.meta?.requiresAuth === false) {
    next()
  }
  else if (to.meta?.requiresAuth !== false && !userStore.isLogin()) {
    // uuid 登录
    let deviceUuid = localStorage.getItem('device-uuid')
    let isNewUser = false
    if (!deviceUuid) {
      deviceUuid = uuid()
      localStorage.setItem('device-uuid', deviceUuid)
      isNewUser = true
    }
    await userStore.login(deviceUuid, isNewUser)
    // facebook 归因接口
    await useFacebookTrack().init()
    next()
  }
  else {
    try {
      await userStore.init()
    }
    catch (error: any) {
      console.error(error)
      next({
        name: 'error',
        query: {
          level: ERROR_PAGE_LEVEL.DEFAULT,
          message: error.msg || error.message,
          redirect: window.encodeURIComponent(window.location.href),
        },
        replace: true,
      })
    }
    next()
  }
})

// Tracking
router.afterEach((to, from) => {
  const params: Record<string, any> = {
    fromUrl: from.fullPath,
    fromTitle: from.meta?.title ?? '',
    toUrl: to.fullPath,
    toTitle: to.meta?.title ?? '',
  }
  if (to.name === 'book') {
    params.contentId = to.params.contentId
  }
  else if (to.name === 'chapter') {
    params.contentId = to.params.contentId
    params.chapterId = to.params.chapterId
    if (to.query.finishRecommend)
      params.toTitle = '文末推荐'
  }
  else if (to.name === 'activity') {
    params.activityId = to.params.activityId
  }
})

// Store the route stack
router.afterEach((to, from) => {
  const historyStore = useHistoryStore()
  if (['authorize', 'paying'].includes(to.name ?? '')) {
    // Skip record
  }
  else if (to.name === 'history' && to.query.from === 'member') {
    historyStore.$push(to.fullPath)
  }
  // Note: same route || tabs route
  else if (to.name === from.name || ['history', 'home', 'mm', 'category', 'member'].includes(to.name ?? '')) {
    historyStore.$replace(to.fullPath)
  }
  else {
    historyStore.$push(to.fullPath)
  }
})

export default router

/**
 * 创建 Select Option
 * @deprecated 推荐使用 createEnum 替代
 * @param options
 */
export function useOptions<
  T extends readonly {
    readonly value: string | number
    readonly label: string
    readonly key: string | number
    readonly badgeStatus?:
    | 'default'
    | 'error'
    | 'success'
    | 'warning'
    | 'processing'
    readonly [k: string]: any
  }[],
>(options: T) {
  return {
    options: options.map(({ ...args }) => ({ ...args })),
    value: options.reduce(
      (acc, { value, ...args }) => ({ ...acc, [value]: { ...args, value } }),
      {} as { [K in T[number]['value']]: T[number] },
    ),
    key: options.reduce(
      (acc, { key, value, ...args }) => ({
        ...acc,
        [key || value]: { ...args, value, key },
      }),
      {} as {
        [K in T[number]['key'] extends undefined
          ? T[number]['value']
          : T[number]['key']]: T[number]
      },
    ),
  }
}

/** Select Option 转 Enum 枚举对象
 * @see https://github.com/beliefgp/ts-enum-object/blob/main/README.md
 * @example
 * ```ts
 * export const xEnum = createEnum([
 *   { key: 'DOING', value: 1, label: '跟进中' },
 *   { key: 'DONE', value: 3, label: '已成单' },
 *   { key: 'UNLICENSED', value: 2, label: '未成单' },
 * ] as const)
 * clueTrackResultEnum.DONE // 3
 * ```
 */
export function createEnum<T extends EnumItemList>(
  enumItems: T,
): EnumObject<T> {
  const obj = Object.create(null)
  obj.keys = []
  obj.values = []
  obj.labels = []
  obj.options = enumItems
  obj.getItemByKey = getItemByKey
  obj.getItemByValue = getItemByValue

  for (const key in enumItems) {
    if (Object.prototype.hasOwnProperty.call(enumItems, key)) {
      const element = enumItems[key]
      obj[element.key] = element.value
      obj.keys.push(element.key)
      obj.values.push(element.value)
      obj.labels.push(element.label || element.value)
    }
  }

  function getItemByKey<T extends EnumItemList>(
    this: EnumObject<T>,
    key: EnumFieldValue<T, 'key'>,
  ) {
    return this.options.find(item => item.key === key)
  }

  function getItemByValue<T extends EnumItemList>(
    this: EnumObject<T>,
    value: EnumFieldValue<T, 'value'>,
  ) {
    return this.options.find(item => item.value === value)
  }

  return obj
}
export interface EnumItem {
  key: string
  value: any
  label?: string
  [k: string]: any
}
type EnumItemList = Readonly<Readonly<EnumItem>[]>
/**
 * 枚举对象结构
 * @description 目前 TypeScript 下 name 类型自动映射只支持 100 个，应该可以满足绝大部分场景了
 */
type EnumObject<T extends EnumItemList> = EnumObjectKeyValueType<T> & {
  options: EnumItem[]
  keys: Array<EnumFieldValue<T, 'key'>>
  values: Array<EnumFieldValue<T, 'value'>>
  labels: Array<EnumFieldValue<T, 'label'>>
  /** 根据 key 值获取枚举 item */
  getItemByKey<K extends 'key', V extends EnumFieldValue<T, K>>(
    name: V,
  ): EnumItemType<T, K, V>
  getItemByKey(key: string): T[number] | undefined
  /** 根据 value 值获取枚举 item */
  getItemByValue<K extends 'value', V extends EnumFieldValue<T, K>>(
    value: V,
  ): EnumItemType<T, K, V>
  getItemByValue(value: any): T[number] | undefined
}
/** 枚举对象 Key/Value 映射 */
type EnumObjectKeyValueType<T extends EnumItemList> = {
  [key in keyof EnumObjectItem<T, number>]: EnumItemValue<
    T,
    'key',
    key,
    'value'
  >
}
/** 获取枚举 Item 里某个 Key 对应的值 */
type EnumFieldValue<
  T extends EnumItemList,
  K extends EnumFieldKey<T>,
  I extends number = number,
> = T[I][K]
/** 获取枚举 Item 里所有的 Key */
type EnumFieldKey<T extends EnumItemList> = keyof T[number]
type EnumObjectItem<T extends EnumItemList, I extends number> = {
  [key in string as EnumFieldValue<T, 'key', I>]: EnumFieldValue<T, 'value', I>
}
type EnumItemValue<
  T extends EnumItemList,
  K extends EnumFieldKey<T>,
  V extends EnumFieldValue<T, K>,
  R extends EnumFieldKey<T>,
> = R extends keyof EnumItemType<T, K, V> ? EnumItemType<T, K, V>[R] : unknown
/** 根据枚举字段 Key 和对应 Value 获取枚举列表 Item 类型 */
type EnumItemType<
  T extends EnumItemList,
  K extends EnumFieldKey<T>,
  V extends EnumFieldValue<T, K>,
> = IterationEnumItemType<T, K, V, 0>
type IterationEnumItemType<
  T extends EnumItemList,
  K extends EnumFieldKey<T>,
  V extends EnumFieldValue<T, K>,
  I extends number,
> = V extends EnumFieldValue<T, K, I>
  ? T[I]
  : GetNextIndex<I> extends number
    ? IterationEnumItemType<T, K, V, GetNextIndex<I>>
    : EnumItem
type GetNextIndex<I extends number> = IndexList[I] extends undefined
  ? never
  : IndexList[I]
type IndexList = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84,
  85,
  86,
  87,
  88,
  89,
  90,
  91,
  92,
  93,
  94,
  95,
  96,
  97,
  98,
  99,
  100,
]

/* eslint-disable no-console */
import { track } from '@/track'

export default {
  install(app: any) {
    const oldTrace = console.trace
    console.trace = (...args) => {
      console.groupCollapsed.apply(console, args) // eslint-disable-line prefer-spread
      oldTrace('')
      console.groupEnd()
    }

    // #region VUE errorHandler 和 React componentDidCatch
    app.config.errorHandler = (error: any, vm: any, info: any) => {
      const vmName = vm?.$options.name || vm?.$options.__file || ''
      track('errorInfo', {
        description: `Vue渲染错误: 组件为 ${vmName}, 位置为 ${info}`,
        message: error.message,
        type: 'error_vue',

        // 这几个值是测试非要加上去的, 没什么用
        code: null,
        path: null,
        book_id: null,
        chapter_id: null,
      })
    }
    // #endregion

    if (typeof window === 'undefined')
      return

    // #region 全局监控 JS 异常
    window.onerror = function errorHandler(msg, url, lineNo, columnNo, error) {
      if (!error)
        return

      const string = `${msg}`.toLowerCase()
      const substring = 'script error'
      if (string.includes(substring)) {
        track('errorInfo', {
          description: '全局监控 JS 异常: Script Error',
          message: error,
          type: 'script_error',

          // 这几个值是测试非要加上去的, 没什么用
          code: null,
          path: null,
          book_id: null,
          chapter_id: null,
        })
      }
      else {
        track('errorInfo', {
          description: `全局监控 JS 异常: Message: ${msg}, URL: ${url}, Line: ${lineNo}, Column: ${columnNo}`,
          message: error,
          type: 'script_error',

          // 这几个值是测试非要加上去的, 没什么用
          code: null,
          path: null,
          book_id: null,
          chapter_id: null,
        })
      }
      return true
    }
    // #endregion

    // #region 全局监控静态资源异常
    window.addEventListener(
      'error',
      (event) => {
        const error = event.message || event
        if (!error || error === 'Script error.')
          return
        track('errorInfo', {
          description: '全局监控静态资源异常',
          message: error,
          type: 'error_error',

          // 这几个值是测试非要加上去的, 没什么用
          code: null,
          path: null,
          book_id: null,
          chapter_id: null,
        })
        return true
      },
      true,
    )
    // #endregion

    // #region 捕获没有 Catch 的 Promise 异常
    window.addEventListener('unhandledrejection', (e) => {
      e.preventDefault()
      track('errorInfo', {
        description: `捕获没有 Catch 的 Promise 异常: ${e.reason}`,
        message: e.reason,
        type: 'error_unhandledrejection',
      })
      return true
    })
    // #endregion
  },
}

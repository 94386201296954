<script lang="ts" setup>
import { ref } from 'vue'
import { api } from '@/api'
import { useLoadingStore, useToastStore, useUserStore } from '@/stores'

const { $toast } = useToastStore()
const { $showLoading, $hideLoading } = useLoadingStore()

const userStore = useUserStore()

// 注销账户
const unsubscribeVisible = ref(false)
const unsubscribeLoading = ref(false)
async function onUnsubscribe() {
  if (unsubscribeLoading.value)
    return
  unsubscribeLoading.value = true
  try {
    await api.post('/member/sign/out')
    $toast('Account cancellation success')
  }
  catch (error: any) {
    console.error(error)
  }
  finally {
    unsubscribeVisible.value = false
    unsubscribeLoading.value = false
  }
}

// 自动解锁
const autoLockStatus = ref(userStore.$state.autoLock)
const autoLockLoading = ref(false)
async function onAutoLock() {
  if (autoLockLoading.value)
    return
  autoLockLoading.value = true
  try {
    await api.post('/member/update/auto-lock', {
      autoLock: !autoLockStatus.value,
    })
    autoLockStatus.value = !autoLockStatus.value
    $toast('Auto-unlock setting success')
  }
  catch (error: any) {
    console.error(error)
  }
  finally {
    autoLockLoading.value = false
  }
}

const autoSubscriptionStatus = ref(userStore.$state.subscribeRenewal)
const subscriptionConfirmModal = ref(false)
async function onAutoSubscription(force = false) {
  if (autoSubscriptionStatus.value && !force) {
    subscriptionConfirmModal.value = true
    return
  }
  if (force)
    subscriptionConfirmModal.value = false

  if (autoLockLoading.value)
    return
  $showLoading()
  try {
    await api.post(`/member/subscribe/cancelSubscription?cancelAtPeriod=${(!autoSubscriptionStatus.value)}`)
    autoSubscriptionStatus.value = !autoSubscriptionStatus.value
    $toast('Auto subscription renewal setting success')
  }
  catch (error: any) {
    console.error(error)
  }
  finally {
    $hideLoading()
  }
}
</script>

<template>
  <div class="cover">
    <BasicNavbar />
    <div class="mt-48px mx-10px px-20px bg-white b-rd-2 box-shadow">
      <BizTrackClick name="User_Agreement">
        <a
          href="https://h5.rocnovel.com/#/readme"
          class="w-full h-61px flex items-center justify-between border-#F3F5FA"
        >
          <div class="flex items-center space-x-7px">
            <p class="text-15px font-bold">
              User Agreement
            </p>
          </div>
          <i-icon-park-outline-right class="text-#999" />
        </a>
      </BizTrackClick>
      <BizTrackClick name="Privacy_Policy">
        <a
          href="https://h5.rocnovel.com/#/secret"
          class="w-full h-61px flex items-center justify-between border-t border-#F3F5FA"
        >
          <div class="flex items-center space-x-7px">
            <p class="text-15px font-bold">
              Privacy Policy
            </p>
          </div>
          <i-icon-park-outline-right class="text-#999" />
        </a>
      </BizTrackClick>
      <BizTrackClick name="Account_cancellation">
        <div
          class="w-full h-61px flex items-center justify-between border-t border-#F3F5FA"
          @click="unsubscribeVisible = true"
        >
          <div class="flex items-center space-x-7px">
            <p class="text-15px font-bold">
              Account cancellation
            </p>
          </div>
          <i-icon-park-outline-right class="text-#999" />
        </div>
      </BizTrackClick>
      <BizTrackClick :name="autoLockStatus ? 'auto_unlock_off' : 'auto_unlock_on'">
        <div class="w-full h-61px flex items-center justify-between border-t border-#F3F5FA">
          <div class="flex items-center space-x-7px">
            <p class="text-15px font-bold">
              Auto-unlock
            </p>
          </div>
          <BasicSwitch :is-open="autoLockStatus" @change="onAutoLock" />
        </div>
      </BizTrackClick>
      <BizTrackClick
        v-if="userStore.$state.subscribe"
        :name="userStore.$state.subscribe ? 'auto_subscribe_off' : 'auto_subscribe_on'"
      >
        <div class="w-full h-61px flex items-center justify-between border-t border-#F3F5FA">
          <div class="flex items-center space-x-7px">
            <p class="text-15px font-bold">
              Auto subscription renewal
            </p>
          </div>
          <BasicSwitch :is-open="autoSubscriptionStatus" @change="onAutoSubscription" />
        </div>
      </BizTrackClick>
    </div>
    <BasicPopup v-model="unsubscribeVisible" :closable="false">
      <div class="px-20px bg-#fff b-rd-t-2">
        <h3 class="pt-20px pb-15px text-18px text-center">
          Important operation
        </h3>
        <p class="text-#64686F">
          Are you sure you want to close your account?
          If you confirm the cancellation, we will delete all your user information on the platform, including but not limited to your account information (and will also cancel the binding relationship between your account and the third party account), viewing records, recharge and consumption records, and the balance in your account! You will no longer be able to retrieve this account, please exercise caution.
        </p>
        <div class="flex justify-center items-center space-x-20px pb-50px">
          <a
            class="block mt-4 w-100px h-9 lh-9 text-15px text-#65686F text-center bg-#F3F5FA rounded-full"
            @click="onUnsubscribe"
          >
            Verify
          </a>
          <a
            class="block mt-4 w-100px h-9 lh-9 text-15px text-white text-center bg-primary rounded-full"
            @click="unsubscribeVisible = false"
          >
            Cancel
          </a>
        </div>
      </div>
    </BasicPopup>

    <BasicModal v-model="subscriptionConfirmModal" :order="6">
      <div class="p-5">
        <div class="text-12px">
          <p>After canceling the renewal, no deduction will be made in the next period, and you can use it normally in the current period.</p>
        </div>
        <div class="flex justify-center items-center space-x-20px">
          <a
            class="block mt-4 w-100px h-9 lh-9 text-15px text-#65686F text-center bg-#F3F5FA rounded-full"
            @click="subscriptionConfirmModal = false"
          >
            Cancel
          </a>
          <a
            class="block mt-4 w-100px h-9 lh-9 text-15px text-white text-center bg-primary rounded-full"
            @click="onAutoSubscription(true)"
          >
            Confirm
          </a>
        </div>
      </div>
    </BasicModal>
  </div>
</template>

<style lang="scss" scoped>
.cover {
  width: 100%;
}
</style>

import { createEnum } from './options'

// #region 订阅周期
/**
 * 订阅周期
 */
export const SubscriptionCycle = createEnum([
  { value: 1, key: 'DAY', label: 'day' },
  { value: 2, key: 'WEEK', label: 'week' },
  { value: 3, key: 'MONTH', label: 'month' },
  { value: 4, key: 'HALF_YEAR', label: 'semi-annual' },
  { value: 5, key: 'YEAR', label: 'yearly' },
] as const)
// #endregion

<script lang="ts" setup>
import '@unocss/reset/tailwind.css'
import moment from 'moment'
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router/composables'
import { useSessionStorage } from '@vueuse/core'
import { asyncTrack, initTrack } from '@/track'
import { useUserStore } from '@/stores'

const route = useRoute()
const loading = ref(true)
const userStore = useUserStore()

onMounted(async () => {
  // eslint-disable-next-line no-console
  console.log('[App] => onMounted')
  loading.value = false
})

// #region track
const historyRecord = useSessionStorage<any[]>('historyRecord', [])

// pageShow
watch(
  () => route.path,
  async (newVal, oldVal) => {
    if (!userStore.code)
      await userStore.init()

    initTrack()

    if (!oldVal && newVal === '/' && route.name === 'home') {
      // 首次进入 home
      asyncTrack('pageShow', {
        title: 'index_novel',
        route_source: '',
        tab: 'Novel',
      })
      handlehistory(newVal)
      return
    }

    if (!oldVal && newVal === '/')
      return

    asyncTrack('pageShow', {
      title: route.meta?.trackTitle || route.meta?.title || '',
      route_source: `${window.location.protocol}//${window.location.host}${newVal}` || '',
      tab: route.meta?.title || '',
    })

    handlehistory(newVal)
  },
  {
    immediate: true,
  },
)

// pageTime
async function handlehistory(newPath: string) {
  const newRecord = {
    path: `${window.location.protocol}//${window.location.host}${newPath}`,
    time: new Date().getTime(),
    title: route.meta?.trackTitle || route.meta?.title || '',
  }
  historyRecord.value.push(newRecord)

  if (historyRecord.value.length > 1) {
    const len = historyRecord.value.length - 1
    const diffTime = getDiffTimes(historyRecord.value[len - 1].time, historyRecord.value[len].time)

    asyncTrack('pageTime', {
      title: historyRecord.value[len - 1].title || '',
      duration: diffTime,
    })
  }

  // 最多保留 10 条记录
  if (historyRecord.value.length > 10)
    historyRecord.value.shift()
}

function getDiffTimes(timestamp1: number, timestamp2: number) {
  return moment(timestamp2).diff(moment(timestamp1), 'seconds')
}
// #endregion
</script>

<template>
  <div id="app">
    <div v-if="loading">
      <div class="pt-20vh flex justify-center">
        <BasicSpinner class="mx-auto" />
      </div>
    </div>

    <div v-else>
      <RouterView />
      <BasicLoading />
      <BasicToast class="z-1001" />
      <!-- Note: 等待授权完成 -->
    </div>

    <portal-target name="root" multiple />
  </div>
</template>

<style>
html, body {
  height: 100%;
  -webkit-overflow-scrolling: auto;
  overflow: hidden;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, SF UI Text, Helvetica Neue, PingFang SC, Hiragino Sans GB, Microsoft YaHei UI, Microsoft YaHei, Arial, sans-serif;
  touch-action: pan-y;
  font-size: 16px;
  max-width: var(--var-max-body-width, 750px);
  margin: 0 auto;
  background-color: white;
}
#app {
  height: 100%;
  margin: 0 auto;
  background: #F6F7F9;
  position: relative;
  overflow-y: scroll;
}

/** Some global transition  */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* slide-left */
.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.4s ease;
}
.slide-left-enter, .slide-left-leave-to {
  transform: translateX(100%);
}
/* slide-right */
.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.4s ease;
}
.slide-right-enter, .slide-right-leave-to {
  transform: translateX(-100%);
}

/** Some reset css */
p {
  margin: 0;
  overflow-wrap: anywhere;
}
div, input, textarea, button, select, a {
  -webkit-tap-highlight-color: transparent!important;
}
input:focus {
  outline: 0;
}
::-webkit-scrollbar {
  display: none;
}
.box-shadow {
  box-shadow: 0 2px 10px 0 rgba(220, 224, 228, 0.40);
}
</style>
